import { classMap } from 'lit-html/directives/class-map';
import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import {
  KatLitElement,
  register,
  event,
  EventEmitter,
  Keys,
} from '../../shared/base';
import { checkSlots } from '../../shared/slot-utils';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './alert.lit.scss';
import getString from './strings';

const VARIANT_TO_ICON_NAME = {
  success: 'success',
  'gen-ai': 'sparkle',
  danger: 'danger',
  warning: 'warning',
  info: 'info-circle',
};

/**
 * @component {kat-alert} KatalAlert An alert is a component that conveys extra important information related to either the page as a whole, or a particular section on a page.
 * @guideline Do For readability, maximum line width: 50-75 characters
 * @guideline Do For accessibility, always give the message in at least two of these three ways: color, text, icon
 * @guideline Do Convey the severity and urgency within the context of activity.
 * @guideline Do The title should be specific and informative enough that the customer understands what the issue is before they read any text below the title.
 * @guideline Do If populating the CTA slot with an action, use a single link (kat-link). In the rare case where there are multiple potential actions (such as snoozing an alert for one of several predetermined time periods) use a dropdown button, or text links within the content.
 * @guideline Dont It is not recommended to use multiple alerts in one page. If your rare use case requires this, stack the alerts ordered by the urgency with which the customers need to pay attention: error, warning, information, then success.
 * @status Production
 * @theme flo
 * @slot default Contents will be used as the description section of the alert.
 * @slot header Contents will be used as the header section of the alert.
 * @slot cta Contents will appear in the top-right corner of the alert. See guidelines for how this should be used.
 * @example Info {"header": "Informational Alert", "description": "Used to give users context.", "variant":"info"}
 * @example InfoWithCTA {"header": "Informational Alert", "description": "Used to give users context.", "variant":"info", "content":"<kat-link slot=\"cta\" tabindex=\"0\">Click Me</kat-link>"}
 * @example Success {"header": "Success Alert", "description": "Used to let users know an action was successful.", "variant":"success"}
 * @example GenAI {"header": "GenAI Alert", "description": "An alert that is generated by a Generative AI-based system.", "variant":"gen-ai"}
 * @example Danger {"header": "Danger Alert", "description": "Can be used to let users know an error has occurred.", "variant":"danger"}
 * @example Warning {"header": "Warning Alert", "description": "Used to let users know they should be careful with an action they are to perform", "variant":"warning"}
 * @example Slots {"content": "<span slot=\"header\">Header set via <i>slot</i></span>Description set with default slot, can contain <b>arbitrary</b> html, like <a href=\"http://www.amazon.com\">links</a>."}
 * @a11y {keyboard}
 * @a11y {contrast}
 * @a11y {sr}
 */
@register('kat-alert')
export class KatAlert extends KatLitElement {
  /** The main text that will be displayed. May also be set via the `header` slot. */
  @property()
  header?: string;

  /** The secondary text that will be displayed. May also be set via the `default` slot. */
  @property()
  description?: string;

  /** Whether or not the alert is dismissed - Default is false */
  @property()
  dismissed?: boolean;

  /** Whether the alert can be dismissed. When true, the alert will render without a close button. Defaults to false. */
  @property()
  persistent?: boolean;

  /**
   * The state of the alert - Default is info
   * @enum {value} info Info alert - Default
   * @enum {value} gen-ai GenAI alert
   * @enum {value} success Success alert
   * @enum {value} danger Danger alert
   * @enum {value} warning Warning alert
   */
  @property()
  variant = 'info';

  /**
   * Fires when alert dismisses.
   */
  @event('dismiss')
  private _dismiss: EventEmitter<{ dismissed: boolean }>;

  static get styles() {
    return [baseStyles, styles];
  }

  private _dismissAlert() {
    this.dismissed = true;
    this._dismiss.emit({ dismissed: this.dismissed });
  }

  private _closeOnKey(e) {
    if (Keys.Confirm.includes(e.keyCode)) {
      this._dismissAlert();
    }
  }

  /**
   * The icon is large if both the header & description are set, otherwise it is
   * small.
   */
  private _getIconSize() {
    const slots = checkSlots(this);
    const hasDescription = slots.default || this.description;
    const hasHeader = slots.header || this.header;
    return hasDescription && hasHeader ? 'large' : 'small';
  }

  private _childrenChanged() {
    this.requestUpdate();
  }

  render() {
    const size = this._getIconSize();

    const slots = checkSlots(this);

    const headerClasses = {
      empty: !(this.header || slots.header),
      header: true,
    };
    const descriptionClasses = {
      empty: !(this.description || slots.default),
      description: true,
    };
    const ctaClasses = {
      empty: !slots.cta,
      cta: true,
    };

    this.setAttribute('size', size);
    return html`
      <kat-icon
        class="icon"
        name="${VARIANT_TO_ICON_NAME[this.variant || 'info']}"
        size="${size}"
        part="alert-icon"
      ></kat-icon>
      <div class="body" part="alert-body">
        <div class="content">
          <div class="${classMap(headerClasses)}">
            <slot name="header" @slotchange=${this._childrenChanged}>
              ${this.header}
            </slot>
          </div>
          <div class="${classMap(descriptionClasses)}">
            <slot @slotchange=${this._childrenChanged}
              >${this.description}</slot
            >
          </div>
        </div>
        <div class="${classMap(ctaClasses)}">
          <slot name="cta" @slotchange=${this._childrenChanged}></slot>
        </div>
      </div>
      ${this.persistent
        ? nothing
        : html`
            <kat-icon
              role="button"
              tabindex="0"
              class="dismiss"
              name="exit"
              size="small"
              aria-label="${getString('kat-alert-dismiss')}"
              part="alert-dismiss-button"
              @click=${this._dismissAlert}
              @keydown=${this._closeOnKey}
            ></kat-icon>
          `}
    `;
  }
}
